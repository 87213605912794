<template>
  <div class="container-fluid rounded bg-white py-4">
    <!-- <div class="d-flex col-12 p-0"> -->
    <div class="row mb-3">
      <div class="col-12">
        <modal-actions @close="$router.go(-1)" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex flex-wrap justify-content-between">
        <span class="h2 text-secondary">Nouvel objectif</span>
        <div>
          <b-button pill @click.prevent="createItem">Confirmer</b-button>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <div id="add-page" class="row col-12 p-0 mt-4 mx-auto">
      <div class="col-md-6 col-12 p-1">
        <!-- INFORMATION  -->
        <div class="col-12 shdow h-100">
          <div class="col-12 p-0 d-flex">
            <p class="h4 text-secondary my-auto mr-auto">
              Informations générales
            </p>
          </div>
          <div class="col-12 p-0">
            <hr class="bg-secondary mb-3 mt-1" />
          </div>

          <div>
            <b-form-group
              label="Libellé:"
              class="font-weight-bold"
              label-cols-sm="3"
              label-align-sm="left"
            >
              <b-form-input
                class="font-weight-normal"
                placeholder="Libellé"
                v-model="newObjectif.libelle"
                :class="{
                  'is-invalid':
                    $v.newObjectif.libelle.$error &&
                    $v.newObjectif.libelle.$dirty,
                }"
              />

              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newObjectif.libelle.$dirty"
              >
                {{
                  !$v.newObjectif.libelle.required
                    ? "Champ obligatoire"
                    : !$v.newObjectif.libelle.minLength
                    ? `Le champ doit contenir au moins ${$v.newObjectif.libelle.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
          </div>
          <div>
            <b-form-group
              label="Vision:"
              class="font-weight-bold"
              label-cols-sm="3"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newObjectif.vision.$error &&
                    $v.newObjectif.vision.$dirty,
                }"
              >
                <v-select
                  class="font-weight-normal form-control p-0 bg-transparent"
                  style="padding-right: 0 !important ; border: 1px"
                  placeholder="Vision"
                  v-model="newObjectif.vision"
                  :options="visions"
                  disabled
                  label="text"
                  :reduce="(vision) => vision.value"
                  :class="{
                    'is-invalid':
                      $v.newObjectif.vision.$error &&
                      $v.newObjectif.vision.$dirty,
                  }"
                />
              </div>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newObjectif.vision.$dirty"
              >
                {{
                  !$v.newObjectif.vision.required
                    ? "Champ obligatoire"
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
          </div>

          <div>
            <b-form-group
              label="Organisation:"
              class="font-weight-bold"
              label-cols-sm="3"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newObjectif.organisationResp.$error &&
                    $v.newObjectif.organisationResp.$dirty,
                }"
              >
                <v-select
                  class="font-weight-normal form-control p-0 bg-transparent"
                  style="padding-right: 0 !important ; border: 1px"
                  placeholder="Organisation"
                  v-model="newObjectif.organisationResp"
                  :options="organisations"
                  label="text"
                  disabled
                  :reduce="(organisation) => organisation.value"
                  :class="{
                    'is-invalid':
                      $v.newObjectif.organisationResp.$error &&
                      $v.newObjectif.organisationResp.$dirty,
                  }"
                />
              </div>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newObjectif.organisationResp.$dirty"
              >
                {{
                  !$v.newObjectif.organisationResp.required
                    ? "Champ obligatoire"
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
          </div>
          <div>
            <b-form-group
              label="Responsable:"
              class="font-weight-bold"
              label-cols-sm="3"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newObjectif.responsable.$error &&
                    $v.newObjectif.responsable.$dirty,
                }"
              >
                <v-select
                  label="text"
                  class="font-weight-normal form-control p-0 bg-transparent"
                  style="padding-right: 0 !important ; border: 1px"
                  placeholder="Responsable"
                  v-model="newObjectif.responsable"
                  :options="responsables"
                  :reduce="(responsable) => responsable.value"
                  :class="{
                    'is-invalid':
                      $v.newObjectif.responsable.$error &&
                      $v.newObjectif.responsable.$dirty,
                  }"
                />
              </div>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newObjectif.responsable.$dirty"
              >
                {{
                  !$v.newObjectif.responsable.required
                    ? "Champ obligatoire"
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 p-1">
        <!-- PROGRRESS -->
        <div class="col-12 shdow h-100">
          <div class="col-12 p-0 d-flex">
            <p class="h4 text-secondary my-auto mr-auto">Avancement</p>
          </div>
          <hr class="mt-1 mb-3 bg-secondary" />

          <p style="color: black" class="d-flex col-12 p-0">
            Avancement :
            <span
              class="font-weight-bold col-6 p-0 my-auto"
              style="color: black"
            >
              <b-progress
                class="col-12 p-0 ml-1"
                max="100"
                show-progress
                height="15px"
              >
                <b-progress-bar
                  :value="0"
                  :label="`${0}%`"
                  :style="{ backgroundColor: variant(0) }"
                ></b-progress-bar> </b-progress
            ></span>
          </p>
          <p style="color: black">
            Alignement :
            <span class="font-weight-bold" style="color: black"> 0 </span>
          </p>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <b-tabs class="tab-solid tab-solid-primary">
          <b-tab title="Feuilles de Route" active>
            <FdrTable disabled />
          </b-tab>
          <b-tab title="KPI"> <KpiTable :kpis="[]" disabled /> </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import FdrTable from "./components/FdrTable";
import KpiTable from "./components/KpiTable";

import { mapGetters } from "vuex";
import moment from "moment";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import Swal from "sweetalert2";

export default {
  components: {
    ModalActions,
    FdrTable,
    KpiTable,
  },
  data() {
    return {
      editMode: false,
      editing: "",
      showAddObjectifsModal: false,
      disable: false,
      newObjectif: {
        libelle: "",
        vision: "",
        responsable: "",
        organisationResp: "",
      },

      vision: {
        libelle: null,
        description: null,
        organisation: null,
        responsable: null,
        objectifs: [],
        progress: 0,
        startedAt: moment().format("DD/MM/YYYY"),
        status: null,
      },
      objectifs: [],

      selectedVisionItem: { libelle: "" },
      selectedGoals: [],
      desactivateAllFileModifications: false,
    };
  },
  validations: {
    newObjectif: {
      libelle: {
        required,
        minLength: minLength(5),
      },
      responsable: {
        required,
      },
      vision: {
        required,
      },
      organisationResp: {
        required,
      },
    },
  },
  methods: {
    datePickerFormat,
    variant: function (progress) {
      if (progress <= 20) return "#f77f00";
      else if (progress >= 71) return "#2A8947";
      else if (progress >= 21 && progress <= 70) return "#fed713";
      return "default";
    },
    createItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.dispatch("objectif/createObjectif", this.newObjectif);
      }
    },
  },
  mounted() {
    if (this.VISION){
this.newObjectif.vision = this.VISION["@id"];
this.newObjectif.organisationResp = this.VISION.organisation["@id"];
window.console.log(this.VISION);
    } 
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    // this.$store.dispatch("objectif/fetchObjectif", this.$route.params.id);
    this.$store.dispatch("vision/fetchAllData");
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("objectif", ["OBJECTIF"]), //get current vision
    ...mapGetters("vision", ["VISIONS", "VISION"]),

    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/users/${responsable.id}`,
        text: ` ${responsable.firstname}  ${responsable.lastname}`,
      }));
    },
    organisations() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: organisation["@id"],
        text: organisation.libelle,
      }));
    },
    visions() {
      return this.VISIONS.map((vision) => ({
        value: vision["@id"],
        text: vision.libelle,
      }));
    },
  },
  watch: {
    OBJECTIF() {
      Swal.fire({
        title: "L'objectif est bien créé !",
        type: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "show-objectif",
            params: {
              slug: this.OBJECTIF.slug || "abcd",
              id: this.OBJECTIF.id,
              fromCreation: true,
            },
          }); //redirect to the new task
        }
      });
    },
  },
};
</script>

<style scoped>
#mycontainer {
  height: 70vh;
}
</style>
